import initFilters from './filters';

window.addEventListener('DOMContentLoaded', function () {
    localStorage.setItem('display', 'grid');
    const productList = document.querySelector('.product-list');
    const setView = () => {
        const view = localStorage.getItem('display');
        productList.classList[view == 'grid' ? 'remove' : 'add']('product-list--vertical');
    };

    if(productList) {
        setView();
        document.querySelectorAll('#grid-view').forEach(item => {
            item.addEventListener('click', e => {
                localStorage.setItem('display', 'grid');
                setView();
            });
        });

        document.querySelectorAll('#list-view').forEach(item => {
            item.addEventListener('click', e => {
                localStorage.setItem('display', 'list');
                setView();
            });
        });
    }

    const sortMobileContainer = document.querySelector('#sortMobile');
    const btnSort = document.querySelector('#btnSort');
    const btnSortOpen = document.querySelector('#btnSortOpen');
    const btnSortClose = document.querySelector('#btnSortClose');
    if (btnSort) btnSort.addEventListener('click', e => location = document.querySelector('input[name="list-sort"]:checked').value);
    if (btnSortOpen) btnSortOpen.addEventListener('click', e => sortMobileContainer.classList.add('active'));
    if (btnSortClose) btnSortClose.addEventListener('click', e => sortMobileContainer.classList.remove('active'));

  initFilters();
});
