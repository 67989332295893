export default function initFilters() {
  $('#button-filter').on('click', function() {
    const filter = [];

    $('input[name^=\'filter\']:checked').each(function(element) {
      filter.push(this.value);
    });
    const getParams = window.location.search.split('?');

    if(getParams.length === 1) {
      window.location.search += '?filter=' + filter.join(',');
    }
    else {
      window.location.search += '&filter=' + filter.join(',');
    }
  });

  /* Filters */
  const filterContainer = document.querySelector('.product-filters');
  if(!filterContainer) return;

  const btnOpen = document.querySelector('#btnFilterOpen');
  const btnClose = filterContainer.querySelector('#btnFilterClose');
  const categories = document.querySelectorAll('.filter-group-name');
  if(btnOpen) btnOpen.addEventListener('click', e => filterContainer.classList.add('active'));
  if(btnClose) btnClose.addEventListener('click', e => filterContainer.classList.remove('active'));
  if(categories) categories.forEach(category => category.addEventListener('click', e => e.target.classList.toggle('active')));

  const filterForm = document.querySelector('#filterForm');
  const btnClear = document.querySelectorAll('button[role="clear-filters"]');
  btnClear.forEach(btn => btn.addEventListener('click', e => {
    clearForm(filterForm);
    refreshFilters();
  }));

  /* Quick filter */
  const filters = document.querySelectorAll('input[name^="filter"]');
  const quickFilterLists = document.querySelectorAll('.quick-filter-list');
  const quickFilterItemTemplate = ({filterId, filterGroupName, filterName}) => `
    <li class="quick-filter-list-item" data-filter-id="${filterId}">
      <div>
        <span class="quick-filter-list-item-category">${filterGroupName}</span>
        <span class="quick-filter-list-item-name">${filterName}</span>
      </div>
      <button data-filter-id="${filterId}">&times;</button>
    </li>
  `;
  function refreshFilters() {
    const items = [...document.querySelectorAll('input[name^="filter"]:checked')]
      .map(filter => quickFilterItemTemplate(filter.dataset))
      .join('');
    quickFilterLists.forEach(list => {
      list.innerHTML = items;
      list.parentNode.classList.add('changed');
    });
  }

  filters.forEach(input => input.addEventListener('change', function(e) {
    refreshFilters();
  }));

  quickFilterLists.forEach(list => list.addEventListener('click', e => {
    if(e.target.tagName == 'BUTTON') {
      const relatedInput = document.querySelector([`input[data-filter-id="${e.target.dataset.filterId}"]`]);
      relatedInput.checked = false;
      refreshFilters();
    }
  }));
  document.querySelectorAll('button[role="search"').forEach(btn => btn.addEventListener('click', () => $('#button-filter').click()))
}

function clearForm(myFormElement) {
  const elements = myFormElement.elements;
  myFormElement.reset();

  for(let i=0; i<elements.length; i++) {
    const field_type = elements[i].type.toLowerCase();

    switch(field_type) {
      case "text":
      case "password":
      case "textarea":
      case "hidden":
        elements[i].value = "";
        break;
      case "radio":
      case "checkbox":
        if (elements[i].checked) {
          elements[i].checked = false;
        }
        break;
      case "select-one":
      case "select-multi":
        elements[i].selectedIndex = -1;
        break;
      default:
        break;
    }
  }
}
