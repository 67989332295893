import autocomplete from './autocomplete'
import productList from './productList'
import header from './header'
import LazyLoad from "vanilla-lazyload";


function findAncestor (el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

window.addEventListener('DOMContentLoaded', () => {
  const lazyLoad = new LazyLoad({
    elements_selector: ".lazy"
    // ... more custom settings?
  });
})
