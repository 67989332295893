$(document).ready(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const header = document.querySelector('.header');
    const navMenu = document.querySelector('#nav-menu');
    const menuToggle = document.querySelector('#menu-toggle');
    const headerMain = document.querySelector('.header__main');
    const toggleMenu = () => {
        const active = navMenu.classList.toggle('active');
        body.classList.toggle('noscroll', active);
        html.classList.toggle('noscroll', active);
    }
    
    menuToggle.addEventListener('click', toggleMenu);
    window.addEventListener('scroll', function () {
        if(headerMain.offsetTop + 200 < window.pageYOffset) {
            headerMain.classList.add('sticky');
            navMenu.classList.add('sticky');
            header.clientHeight += headerMain;
        }
        else if(window.pageYOffset < 300 ) {
            headerMain.classList.remove('sticky');
            navMenu.classList.remove('sticky');
            header.clientHeight -= headerMain;
        }
    })
    document.querySelector('#btnSearchMobile').addEventListener('click', e => {
        document.querySelector('.search-wrap').classList.add('active');
        document.querySelector('#inputSearch').focus();
    });
    document.querySelector('#btnSearchBack').addEventListener('click', e => {
        document.querySelector('.search-wrap').classList.remove('active');
    });
    document.querySelectorAll('.menu-link').forEach(el => el.addEventListener('click', function (e) {
        if(this.parentNode.classList.contains('has-children')) {
            e.preventDefault();
            this.parentNode.classList.toggle('active');
        }
    }));
    document.querySelector('body').addEventListener('click', e => {
        if(navMenu.classList.contains('active') && !$(e.target).parents('.menu').length && !$(e.target).parents('#menu-toggle').length) {
            toggleMenu();
        }
    })
});