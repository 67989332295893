function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

window.addEventListener('DOMContentLoaded', function () {
    const searchInput = document.querySelector('#inputSearch');
    const container = document.querySelector('#searchResults');
    const results = container.querySelector('ul');
    const detailed = container.querySelector('.search-detailed');

    const search = () => {
        let url = $('base').attr('href') + 'index.php?route=product/search';

        const value = searchInput.value;

        if (value) {
            url += '&search=' + encodeURIComponent(value);
        }

        location = url;
    }

    searchInput.addEventListener('keyup', debounce(e => {
       if(e.target.value.length > 1) {
         container.classList.add('active');
           $.post('/index.php?route=product/search/autocomplete', {q: e.target.value}, response => {
             results.innerHTML = response.reduce((html, product) => {
              return html + `<li><a href="${product.href}"><img src="${product.image}">${product.name}</a></li>`;
             }, '');
           }, 'json');
       }
       else {
         container.classList.remove('active');
         results.innerHTML = '';
       }
    }, 500));

    detailed.addEventListener('click', search);

    document.querySelector('#autocompleteForm').addEventListener('submit', e => {
        e.preventDefault();
        search();
    });

    document.querySelector('#btnSearch').addEventListener('click', search);

});
